import { useQuery } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PickListService from '../../../../services/PickListService';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import ActionEditorBase from '../ActionEditorBase';
import { createPickListSource } from '../PickListSource';
import ShowInResourceView from '../ShowInResourceView';
import { useCurrentClient, useCurrentTenantId } from '../../../../global-state/Clients';

const SelectListResourceEditor: FC<EditorProps> = (props) => {
  const { action, patchData } = props;
  const { sourceType } = action?.data || {};

  const client = useCurrentClient((x) => x.value);
  const currentTenantId = useCurrentTenantId((x) => x.value);

  const { t } = useTranslation(['form-builder', 'organisation']);

  const {
    data: picklistSources = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['picklists', action.id],
    queryFn: () =>
      PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id }).then((res) => {
        return createPickListSource(res.data, t);
      }),
  });

  const onSourceTypeChanged = (option: Item) => {
    patchData({ sourceType: option.id });
  };

  const picklistSourceText = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType)?.text;
  const picklistSourceValue = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType)?.value;

  const picklistLinkProvider = useCallback(
    (item: Item) => {
      return item.value ? `/clients/${item.value}/picklists/${item.id}` : `/builder/${currentTenantId}/picklists/${item.id}`;
    },
    [currentTenantId],
  );

  return (
    <div data-cy="select-list-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      {sourceType && <div className="text-dpm-12 mt-4">{t('form-builder:action-properties.select-list.resource.source')}</div>}
      <DropdownSelect
        data-cy="source"
        className={`${!sourceType && 'mt-8'}`}
        placeholder={t('form-builder:action-properties.select-list.resource.source')}
        value={{ id: sourceType, text: picklistSourceText || '', value: picklistSourceValue || '' }}
        options={picklistSources}
        onChange={onSourceTypeChanged}
        customListRenderer={OpenInNewTabValueRendererWrapper(picklistLinkProvider)}
        isFetching={isFetching}
        onOpen={refetch}
      />

      <ShowInResourceView {...props} />
    </div>
  );
};

export default SelectListResourceEditor;
